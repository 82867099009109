import { useState } from "react";
import { errorMessage } from "../../../../utils/error";
import { FormPersonalDataProps } from "./FromInterface.model";
import { ButtonDS, InputDS } from "get-life-storybook-ts";

const FormPersonalData = ({
  broker,
  emailDisabled,
  error,
  setValue,
  translate,
  values,
  disabled = false,
}: FormPersonalDataProps): JSX.Element => {
  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

  const [isActiveRelatedEmail, setIsActiveRelatedEmail] =
    useState<boolean>(false);
  const ENVIROMENT = process.env.REACT_APP_ENVIROMENT;

  // const BROKER_FR = ((ENVIROMENT === "LOCAL" || ENVIROMENT === "DEV" || ENVIROMENT === "TEST") && (broker.brokerageId === 459)) || (ENVIROMENT === "PRD" && broker.brokerageId === 459);
  // const isGetlifeBroker = broker.brokerageId === 1 || BROKER_FR;
  const isGetlifeBroker = true;

  return (
    <>
      <InputDS
        disabled={disabled}
        label={translate("global.name")}
        onChange={(e) => {
          setValue("name", e.target.value);
        }}
        error={errorMessage(error, "name")}
        value={values["name"]}
      />

      <InputDS
        disabled={disabled}
        label={translate("global.lastName1")}
        onChange={(e) => {
          setValue("lastName", e.target.value);
        }}
        error={errorMessage(error, "lastName")}
        value={values["lastName"]}
      />
      {(DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it") && (
        <InputDS
          disabled={disabled}
          label={translate("global.lastName2")}
          onChange={(e) => {
            setValue("lastName2", e.target.value);
          }}
          error={errorMessage(error, "lastName2")}
          value={values["lastName2"]}
        />
      )}
      <InputDS
        disabled={disabled || emailDisabled}
        label={translate("global.email")}
        onChange={(e) => {
          setValue("email", e.target.value);
        }}
        error={errorMessage(error, "email")}
        value={values["email"]}
      />
      {(isActiveRelatedEmail || values["relatedEmail"]) && (
        <InputDS
          label={translate("calculator.label.emailRelated")}
          placeholder={translate("calculator.placeholder.emailRelated")}
          onChange={(e) => {
            setValue("relatedEmail", e.target.value);
          }}
          error={errorMessage(error, "relatedEmail")}
          value={values["relatedEmail"]}
        />
      )}
      <InputDS
        disabled={disabled}
        label={translate("global.phone")}
        onChange={(e) => {
          setValue("phone", e.target.value);
        }}
        error={errorMessage(error, "phone")}
        value={values["phone"]}
        type="tel"
      />
      {isGetlifeBroker && !isActiveRelatedEmail && !values["relatedEmail"] && (
        <div className="col-span-3">
          <ButtonDS
            ghost={true}
            label={translate("calculator.button.relatedEmail")}
            buttonType="tertiary"
            leftIcon="PlusIcon"
            size="32"
            onClick={() => setIsActiveRelatedEmail(true)}
            disabled={disabled}
          />
        </div>
      )}
    </>
  );
};

export default FormPersonalData;
