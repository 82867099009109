import React from "react";
import { errorMessage } from "../../../../utils/error";
import { FormPhysicalPersonProps } from "./FromInterface.model";
import { CheckboxButton, InputDS } from "get-life-storybook-ts";

const FormPhysicalPersonES = ({
  translate,
  values,
  setValue,
  error,
  handleCheck,
}: FormPhysicalPersonProps) => {
  return (
    <>
      <InputDS
        value={values["name"]}
        label={translate("global.name")}
        onChange={(e) => setValue("name", e.target.value)}
        name="name"
        placeholder={translate("beneficiaries.placeholder.name")}
        error={errorMessage(error, "name")}
      />
      <InputDS
        value={values["lastName"]}
        label={translate("global.lastName1")}
        onChange={(e) => setValue("lastName", e.target.value)}
        name="lastName"
        placeholder={translate("beneficiaries.placeholder.lastName")}
        error={errorMessage(error, "lastName")}
      />
      <InputDS
        value={values["lastName2"]}
        label={translate("global.lastName2")}
        onChange={(e) => setValue("lastName2", e.target.value)}
        name="lastName2"
        placeholder={translate("beneficiaries.placeholder.lastName2")}
        error={errorMessage(error, "lastName2")}
      />
      <br />
      <div>
        <InputDS
          value={values["idNumber"]}
          label={translate("global.idCard")}
          onChange={(e) => setValue("idNumber", e.target.value)}
          name="idNumber"
          placeholder={translate("beneficiaries.placeholder.idNumber")}
          error={errorMessage(error, "idNumber")}
          disabled={values["checked"]}
        />
        <div className="BodyM text-[#6F6F6F] flex flex-row gap-[8px] items-center mt-[12px]">
          <CheckboxButton
            label={""}
            value={values["checked"]}
            checked={values["checked"]}
            onChange={handleCheck}
            size="S"
            variant="SimpleCheck"
          />
          {translate("postStack.checkBox.idNumber")}
        </div>
      </div>
      <InputDS
        value={values["capital"]}
        label={translate("global.capital")}
        onChange={(e) => setValue("capital", e.target.value)}
        name="capital"
        type="number"
        placeholder={translate("beneficiaries.placeholder.capital")}
        error={errorMessage(error, "capital")}
      />
    </>
  );
};

const FormPhysicalPersonFR = ({
  translate,
  values,
  setValue,
  error,
  handleCheck,
}: FormPhysicalPersonProps) => {
  console.log('values["birthdate"]', values["birthdate"]);
  return (
    <>
      <InputDS
        value={values["name"]}
        label={translate("global.name")}
        onChange={(e) => setValue("name", e.target.value)}
        placeholder={translate("beneficiaries.placeholder.name")}
        error={errorMessage(error, "name")}
      />
      <InputDS
        value={values["lastName"]}
        label={translate("global.lastName1")}
        onChange={(e) => setValue("lastName", e.target.value)}
        placeholder={translate("beneficiaries.placeholder.lastName")}
        error={errorMessage(error, "lastName")}
      />
      <InputDS
        value={values["lastName2"]}
        label={translate("global.lastName2")}
        onChange={(e) => setValue("lastName2", e.target.value)}
        placeholder={translate("beneficiaries.placeholder.lastName2")}
        error={errorMessage(error, "lastName2")}
      />
      <InputDS
        value={values["maidenName"]}
        label={translate("global.maidenName")}
        onChange={(e) => setValue("maidenName", e.target.value)}
        placeholder={translate("global.placeholder.maidenName")}
        error={errorMessage(error, "maidenName")}
      />
      <InputDS
        type="date"
        value={values["birthdate"]}
        onChange={(e) => setValue("birthdate", e.target.value)}
        label={translate("calculator.label.birthday")}
      />
      <InputDS
        value={values["birthCity"]}
        label={translate("global.birthCity")}
        onChange={(e) => setValue("birthCity", e.target.value)}
        placeholder={translate("global.placeholder.birthCity")}
        error={errorMessage(error, "birthCity")}
      />
      <div>
        <InputDS
          value={values["idNumber"]}
          label={translate("global.idCard")}
          onChange={(e) => setValue("idNumber", e.target.value)}
          placeholder={translate("beneficiaries.placeholder.idNumber")}
          error={errorMessage(error, "idNumber")}
          disabled={values["checked"]}
        />
        <div className="BodyM text-[#6F6F6F] flex flex-row gap-[8px] items-center mt-[12px]">
          <CheckboxButton
            label={translate("postStack.checkBox.idNumber")}
            value={values["checked"]}
            checked={values["checked"]}
            onChange={handleCheck}
            size="S"
            variant="SimpleCheck"
          />
          {translate("postStack.checkBox.idNumber")}
        </div>
      </div>
      <InputDS
        value={values["capital"]}
        label={translate("global.capital")}
        onChange={(e) => setValue("capital", e.target.value)}
        type="number"
        placeholder={translate("beneficiaries.placeholder.capital")}
        error={errorMessage(error, "capital")}
      />
    </>
  );
};

const FormPhysicalPerson: React.FC<FormPhysicalPersonProps> = ({
  error,
  setValue,
  translate,
  values,
  handleCheck,
}): JSX.Element => {
  if (process.env.REACT_APP_DOMAIN_LOCALE === "es") {
    return (
      <FormPhysicalPersonES
        error={error}
        setValue={setValue}
        translate={translate}
        values={values}
        handleCheck={handleCheck}
      />
    );
  } else {
    return (
      <FormPhysicalPersonFR
        error={error}
        setValue={setValue}
        translate={translate}
        values={values}
        handleCheck={handleCheck}
      />
    );
  }
};

export default FormPhysicalPerson;
