import { requiredFieldsPostStack } from "../../../../utils/staticData";
import PostStackQuestion from "../../organisms/PostStackQuestions/PostStackQuestion.component";
import "./PostStack.styles.scss";
import PostStackController from "./PostStack.controller";
import { GlobalIcon } from "get-life-storybook-ts";
import { useEffect } from "react";

const PostStack = (): JSX.Element => {
  const {
    activeForm,
    emailDisabled,
    handleIsAnswer,
    initialValues,
    isAnswered,
    petitions,
    postStackQuestionTypes,
    selectData,
    translate,
    terzoData,
    setTerzoData,
    showTerzo,
    setShowTerzo,
    checkedConditions,
    setCheckedConditions,
    terzoError,
    nationalities,
    provincies,
    areInitialValues,
    navigate,
  } = PostStackController();

  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

  useEffect(() => {
    if (areInitialValues(isAnswered)) navigate("/pay");
  }, [areInitialValues(isAnswered)]);

  return (
    <div className="max-w-[1280px] mx-auto my-0 p-[32px] box-content">
      <div className="bg-white pt-[24px] pb-[32px] px-[40px] rounded-[8px] shadow-[0px_-1px_0px_0px_rgba(66,_66,_66,_0.10)_inset,_0px_1px_3px_0px_rgba(66,_66,_66,_0.10)]">
        <div className="flex flex-row gap-[16px]">
          <GlobalIcon iconName="CheckupListIcon" color="#424242" />
          <div className="flex flex-col gap-[12px]">
            <p className="BodyL font-bold text-[#424242]">
              {translate("postStack.header.title")}
            </p>
            <p className="BodyM text-[#424242]">
              {translate("postStack.header.subtitle")}
            </p>
          </div>
        </div>
        <div className="bg-[#F5F7FA] rounded-[12px] p-[24px] flex flex-col gap-[24px] mt-[24px]">
          <p className="BodyL font-bold text-[#424242]">
            {translate("poststack.label.title")}
          </p>
          <div className="flex flex-col gap-[8px]">
            {initialValues &&
              postStackQuestionTypes.map((questionType, idx) => {
                return (
                  activeForm[questionType] && (
                    <PostStackQuestion
                      emailDisabled={emailDisabled}
                      data={selectData[questionType]}
                      fetch={petitions[questionType]}
                      initialValues={initialValues[questionType]}
                      isAnswered={isAnswered}
                      requiredFields={requiredFieldsPostStack[questionType]}
                      setIsAnswered={handleIsAnswer}
                      title={translate(`postStack.title.${questionType}`)}
                      type={questionType}
                      variantTitle="bold"
                      terzoData={terzoData}
                      setTerzoData={setTerzoData}
                      showTerzo={showTerzo}
                      setShowTerzo={setShowTerzo}
                      terzoError={terzoError}
                      checkedConditionsText={
                        DOMAIN === "it"
                          ? translate("questions.beneficiaries.checkbox")
                          : null
                      }
                      checkedConditions={checkedConditions}
                      setCheckedConditions={setCheckedConditions}
                      nationalities={nationalities}
                      provinces={provincies}
                    />
                  )
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostStack;
