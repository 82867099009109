import React from "react";
import { Answers } from "../../../../api/request/Lead/Model/Response/NomenclatorResponse.model";
import { errorMessage } from "../../../../utils/error";
import AutocompleteGoogle from "../../../components/autcompleteGMaps/AutocompleteGoogle";
import { AddressValues } from "../../pages/PostStack/PostStack.controller";
import { FormAddressProps } from "./FromInterface.model";
import { InputDS, PredictiveSearchDS, SelectDS } from "get-life-storybook-ts";

const FormAddress = ({
  disabled,
  error,
  provinceList,
  setValue,
  setValues,
  translate,
  values,
}: FormAddressProps): JSX.Element => {
  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;
  return (
    <>
      <div className="col-span-2">
        <AutocompleteGoogle
          answer={values["addressLine"]}
          disabled={disabled}
          error={errorMessage(error, "addressLine")}
          handleDirection={(form: AddressValues) => {
            const correctValue: any =
              typeof form.province === "string"
                ? form.province
                : form.province?.value;
            const correctProvince: string =
              correctValue === "Illes Balears" ? "Baleares" : correctValue;
            let province: Answers | undefined | string;
            if (DOMAIN === "es" || DOMAIN === "pt") {
              province = provinceList.find(
                (item) => item.value === correctProvince
              );
            } else {
              province = correctProvince;
            }
            if (DOMAIN === "it") province = "";
            setValues({ ...form, province });
          }}
          initialVal={values["addressLine"]}
          label={translate("global.address")}
          placeholder={"Ej. Calle de Santa Engracia 90"}
          translate={translate}
        />
      </div>
      {DOMAIN === "it" ? (
        <InputDS
          label={translate("global.number")}
          placeholder={translate("poststack.placeholder.number")}
          value={values["number"]}
          onChange={(e) => setValue("number", e.target.value)}
          disabled={disabled}
          error={errorMessage(error, "number")}
        />
      ) : (
        <div />
      )}
      <InputDS
        label={translate("global.zipCode")}
        placeholder={translate("poststack.placeholder.postalCode")}
        value={values["zipcode"]}
        onChange={(e) => setValue("zipcode", e.target.value)}
        type="number"
        disabled={disabled}
        error={errorMessage(error, "zipcode")}
      />
      {DOMAIN === "it" ? (
        <PredictiveSearchDS
          label={translate("global.province")}
          error={errorMessage(error, "province")}
          value={values["province"] ? values["province"].label : ""}
          onChange={(value: string) => setValue("province", value)}
          options={provinceList}
          placeholder={translate("global.placeholder.province")}
          sortOptionsByValue={true}
        />
      ) : DOMAIN === "es" || DOMAIN === "pt" ? (
        <SelectDS
          label={translate("global.province")}
          placeholder={translate("global.placeholder.province")}
          value={values["province"]}
          error={errorMessage(error, "province")}
          onChange={(e) => setValue("province", e.target.value)}
          options={provinceList}
          disabled={disabled}
        />
      ) : (
        <InputDS
          label={translate("global.province")}
          placeholder={translate("global.placeholder.province")}
          value={values["province"]}
          onChange={(e) => setValue("province", e.target.value)}
          disabled={disabled}
          error={errorMessage(error, "province")}
        />
      )}
      <InputDS
        label={translate("global.city")}
        placeholder={translate("poststack.placeholder.city")}
        value={values["city"]}
        onChange={(e) => setValue("city", e.target.value)}
        disabled={disabled}
        error={errorMessage(error, "city")}
      />
    </>
  );
};

export default FormAddress;
