import React from "react";
import { errorMessage } from "../../../../utils/error";
import { FormProps } from "./FromInterface.model";
import { InputDS } from "get-life-storybook-ts";

const FormBankEntity: React.FC<FormProps> = ({
  error,
  setValue,
  translate,
  values,
}): JSX.Element => {
  return (
    <div className="flex flex-col gap-[24px]">
      <InputDS
        value={values["bankName"]}
        label={translate("postStack.bankEntityForm.name")}
        onChange={(e) => setValue("bankName", e.target.value)}
        placeholder={translate("beneficiaries.placeholder.bankName")}
        error={errorMessage(error, "bankName")}
      />
      <InputDS
        value={values["loanMortgageNumber"]}
        label={translate("postStack.bankEntityForm.idNumber")}
        onChange={(e) => setValue("loanMortgageNumber", e.target.value)}
        placeholder={translate("beneficiaries.placeholder.loanMortgageNumber")}
        error={errorMessage(error, "loanMortgageNumber")}
      />
      <InputDS
        value={values["capital"]}
        type="number"
        label={translate("global.capital")}
        onChange={(e) => setValue("capital", e.target.value)}
        placeholder={translate("beneficiaries.placeholder.name")}
        error={errorMessage(error, "capital")}
      />
    </div>
  );
};

export default FormBankEntity;
