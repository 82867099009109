import { useEffect, useState } from "react";
import { errorMessage } from "../../../../../utils/error";
import { FormNationalityProps } from "../FromInterface.model";
import "./FormNationality.styles.scss";
import { ButtonDS, InputDS, SelectDS } from "get-life-storybook-ts";

const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

const FormNationality = ({
  error,
  setValue,
  translate,
  values,
  countries,
  disabled,
  addNationality,
  cityList,
}: FormNationalityProps): JSX.Element => {
  const [extraNationality, setExtraNationality] = useState<boolean>(false);

  useEffect(() => {
    setValue("cityOfBirth", "");
  }, [values["nationality"]]);

  return (
    <>
      <SelectDS
        label={translate("global.nationality")}
        placeholder={translate("global.select.list")}
        options={countries}
        onChange={(e) => {
          const value = countries.find((c) => c.value === e.target.value);
          setValue("nationality", value);
        }}
        value={values["nationality"]?.value}
        disabled={disabled}
      />
      <SelectDS
        label={translate("global.residence")}
        placeholder={translate("global.select.list")}
        options={countries}
        onChange={(e) => {
          const value = countries.find((c) => c.value === e.target.value);
          setValue("citizenship", value);
        }}
        value={values["citizenship"]?.value}
        disabled={values["citizenship"]}
        error={errorMessage(error, "citizenship")}
      />
      <InputDS
        value={values["idNumber"]}
        label={translate("global.idCard")}
        onChange={(e) => setValue("idNumber", e.target.value)}
        disabled={disabled}
        error={errorMessage(error, "idNumber")}
      />
      {extraNationality && (
        <SelectDS
          label={translate("global.nationality")}
          placeholder={translate("global.select.list")}
          options={countries}
          onChange={(e) => {
            const value = countries.find((c) => c.value === e.target.value);
            setValue("nationality2", value);
          }}
          value={values["nationality2"]?.value}
          error={errorMessage(error, "nationality2")}
          disabled={disabled}
        />
      )}
      {DOMAIN === "it" && values["nationality"]?.value === "IT" && cityList && (
        <SelectDS
          label={translate("global.cityOfBirth")}
          placeholder={translate("global.select.list")}
          options={cityList}
          onChange={(e) => {
            const value = cityList.find((c) => c.value === e.target.value);
            setValue("cityOfBirth", value);
          }}
          value={values["cityOfBirth"]?.value}
          error={errorMessage(error, "cityOfBirth")}
          disabled={disabled}
        />
      )}

      {DOMAIN === "it" && values["nationality"]?.value !== "IT" && (
        <InputDS
          label={translate("global.cityOfBirth")}
          value={values["cityOfBirth"]}
          onChange={(e) => setValue("cityOfBirth", e.target.value)}
          disabled={disabled}
          error={errorMessage(error, "cityOfBirth")}
        />
      )}
      <div className="col-span-3">
        {addNationality &&
          process.env.REACT_APP_DOMAIN_LOCALE !== "fr" &&
          (extraNationality ? (
            <ButtonDS
              ghost={true}
              label={translate("poststack.remove.second.nationality")}
              buttonType="tertiary"
              leftIcon="MinusIcon"
              size="32"
              onClick={() => setExtraNationality(false)}
              disabled={disabled}
            />
          ) : (
            <ButtonDS
              ghost={true}
              label={translate("poststack.add.second.nationality")}
              buttonType="tertiary"
              leftIcon="PlusIcon"
              size="32"
              onClick={() => setExtraNationality(true)}
              disabled={disabled}
            />
          ))}
      </div>
    </>
  );
};

export default FormNationality;
