import React from "react";
import FormOtherBeneficiaries from "../FormOtherBeneficiaries/FormOtherBeneficiaries.component";
import { FormBeneficiariesProps } from "../FromInterface.model";
import {
  CheckboxAtom,
  DateInput,
  DropDown,
  PredictiveSearch,
  RadioButton,
  SingleSelect,
  TextInput,
  Typography,
} from "get-life-storybook-ts";
import AutocompleteGoogle from "get-life-storybook-ts/lib/components/Inputs/AutoCompleteGoogle/AutoCompleteGoogle";

const FormBeneficiaries: React.FC<FormBeneficiariesProps> = ({
  beneficiaries,
  values,
  translate,
  setValue,
  disabled,
  terzoData,
  setTerzoData,
  showTerzo,
  setShowTerzo,
  terzoError,
  checkedConditionsText,
  checkedConditions,
  setCheckedConditions,
  countryList,
  cityList,
}) => {
  const [otherBeneficiaries, setOtherBeneficiaries] = React.useState<string>(
    values.beneficiaries.value
  );

  React.useEffect(() => {
    beneficiaries.forEach((index) => {
      index.label = translate(index.label);
    });
  }, []);

  const checkBoxQuery = (
    <Typography
      variant="BodyMedium"
      text=""
      className="text-body-md"
      color="dark-gray-100"
    >
      Voglio garantire un capitale ai beneficiari in caso di mia scomparsa.
    </Typography>
  );

  const optionsTerzo = [
    {
      value: "yes",
      text: "Sì",
      label: "Sì",
    },
    {
      value: "no",
      text: "No",
      label: "No",
    },
  ];

  return (
    <>
      <div className="flex flex-col gap-[24px]">
        <div className="flex flex-row gap-[64px] pl-[24px]">
          {beneficiaries.map((b) => {
            return (
              <RadioButton
                checked={values.beneficiaries?.value === b.value}
                value={b.value}
                onChange={() => {
                  setValue("beneficiaries", b);
                  setOtherBeneficiaries(b.value);
                }}
                label={translate(b.label)}
                variant="SimpleRadio"
              />
            );
          })}
        </div>
        {otherBeneficiaries === "other" && (
          <FormOtherBeneficiaries
            translate={translate}
            values={values}
            disabled={disabled}
            setValue={setValue}
          />
        )}
      </div>
      {checkedConditionsText && (
        <>
          <div className="w-full mt-[8px]">
            <CheckboxAtom
              isLegal
              checked={checkedConditions}
              label={checkBoxQuery}
              onValueChange={setCheckedConditions}
              dataCy="beneficiaries-check"
            />
          </div>
          <div className="w-full">
            <div className="border-t border-gray-2 pt-[32px] pb-[40px] mt-[24px]">
              <Typography
                text={"Terzo referente"}
                variant="H3"
                className="text-left"
              />
              <div className="grid grid-cols-1 md:grid-cols-[1fr,258px] gap-[24px] mt-[32px]">
                <Typography
                  text="Hai esigenze specifiche di riservatezza e intendi nominare un referente terzo (incaricato) diverso dal beneficiario, a cui la Società può fare riferimento in caso di decesso?"
                  variant="BodyMedium"
                  weight="400"
                  className="text-[#424242] text-left"
                />
                <div className="w-[258px] [&>*>*]:gap-[24px]">
                  <SingleSelect
                    options={optionsTerzo}
                    onValueChange={(e) => setShowTerzo(e as any)}
                    dataCy="terzo-question"
                    defaultSize="S"
                    name="terzo_question"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            {showTerzo === "yes" && (
              <div>
                <Typography
                  text={`Il Contraente desidera nominare il seguente referente terzo, diverso dal beneficiario, a cui la Società può far riferimento in caso di decesso dell’Assicurato:`}
                  variant="BodyMedium"
                  weight="300"
                  className="text-left mt-2 text-[#424242]"
                />
                <div className="form-terzo">
                  <Typography
                    text="Dati personali"
                    variant="BodyMedium"
                    weight="500"
                    className="text-left"
                  />
                  <div className="flex gap-6 mt-2">
                    <TextInput
                      label={"Nome"}
                      validationChecks={[]}
                      onValueChange={(value) =>
                        setTerzoData &&
                        setTerzoData({ ...terzoData!, name: value })
                      }
                      initialValue={terzoData?.name}
                      dataCy="name-terzo-input"
                      placeholder="Mario"
                      initialError={terzoError?.name}
                    />

                    <TextInput
                      label={"Cognome"}
                      validationChecks={[]}
                      onValueChange={(value) =>
                        setTerzoData &&
                        setTerzoData({ ...terzoData!, lastName: value })
                      }
                      initialValue={terzoData?.lastName}
                      dataCy="lastname-terzo-input"
                      placeholder="Rossi"
                      initialError={terzoError?.lastName}
                    />
                  </div>
                  <div className="flex gap-6 mt-2">
                    <TextInput
                      label={"Codice fiscale"}
                      validationChecks={[]}
                      onValueChange={(value) =>
                        setTerzoData &&
                        setTerzoData({ ...terzoData!, fiscalCode: value })
                      }
                      initialValue={terzoData?.fiscalCode}
                      dataCy="dni-terzo-input"
                      placeholder="RSSMRA80A01H501U"
                      initialError={terzoError?.fiscalCode}
                    />

                    <DropDown
                      options={[
                        {
                          value: "M",
                          label: "Maschio",
                        },
                        {
                          value: "F",
                          label: "Femmina",
                        },
                      ]}
                      label={"Sesso"}
                      validationChecks={[]}
                      isSimple
                      onValueChange={(value) =>
                        setTerzoData &&
                        setTerzoData({ ...terzoData!, gender: value })
                      }
                      initialValue={terzoData?.gender}
                      dataCy="gender-terzo-input"
                      placeholder="Selezionare un'opzione"
                      initialError={terzoError?.gender}
                    />
                  </div>
                  <div className="flex gap-6 mt-2">
                    <DateInput
                      initialValue={terzoData?.dateOfBirth}
                      onValueChange={(value: string) =>
                        setTerzoData &&
                        setTerzoData({
                          ...terzoData!,
                          dateOfBirth: value,
                        })
                      }
                      label={"Data di nascita"}
                      textDay={"gg"}
                      textMonth={"mm"}
                      textYear={"aaaa"}
                      validationChecks={[]}
                      dataCy="birthdate-input"
                      initialError={terzoError?.dateOfBirth}
                    />

                    <PredictiveSearch
                      label={"Nazione di nascita"}
                      placeholder="Seleziona un'opzione"
                      options={countryList}
                      validationChecks={[]}
                      initialValue={terzoData?.countryOfBirth}
                      dataCy="countryOfBirth-terzo-input"
                      onValueChange={(value) =>
                        setTerzoData &&
                        setTerzoData({ ...terzoData!, countryOfBirth: value })
                      }
                      initialError={terzoError?.countryOfBirth}
                    />
                  </div>
                  <div className="flex gap-6 mt-2">
                    {terzoData?.countryOfBirth !== "IT" ? (
                      <TextInput
                        label={"Città di nascita"}
                        validationChecks={[]}
                        onValueChange={(value) =>
                          setTerzoData &&
                          setTerzoData({ ...terzoData!, cityOfBirth: value })
                        }
                        initialValue={terzoData?.cityOfBirth}
                        dataCy="name-terzo-input"
                        placeholder="Scrivere la città di nascita"
                        initialError={terzoError?.cityOfBirth}
                      />
                    ) : (
                      <PredictiveSearch
                        label={"Città di nascita"}
                        validationChecks={[]}
                        onValueChange={(value) =>
                          setTerzoData &&
                          setTerzoData({ ...terzoData!, cityOfBirth: value })
                        }
                        initialValue={terzoData?.cityOfBirth}
                        dataCy="name-terzo-input"
                        placeholder="Scrivere la città di nascita"
                        options={cityList}
                        initialError={terzoError?.cityOfBirth}
                      />
                    )}

                    <TextInput
                      label={"E-mail"}
                      validationChecks={[]}
                      onValueChange={(value) =>
                        setTerzoData &&
                        setTerzoData({ ...terzoData!, email: value })
                      }
                      initialValue={terzoData?.email}
                      dataCy="lastname-terzo-input"
                      placeholder="Scrivere l'e-mail"
                      initialError={terzoError?.email}
                    />
                  </div>
                  <Typography
                    text="Indirizzo di residenza"
                    variant="BodyMedium"
                    weight="500"
                    className="text-left mt-[24px]"
                  />
                  <div className="flex gap-6 mt-2">
                    <AutocompleteGoogle
                      DOMAIN="it"
                      handleAddress={(value: string) =>
                        setTerzoData &&
                        setTerzoData({ ...terzoData!, street: value })
                      }
                      handleZipCode={(value: string) =>
                        setTerzoData &&
                        setTerzoData({ ...terzoData!, zipCode: value })
                      }
                      handleProvince={(value: string) => value}
                      handleCity={(value: string) =>
                        setTerzoData &&
                        setTerzoData({ ...terzoData!, city: value })
                      }
                      handleNumber={(value: string) =>
                        setTerzoData &&
                        setTerzoData({ ...terzoData!, streetNumber: value })
                      }
                      label={"Via"}
                      placeholder="Via"
                      translate={undefined}
                      error={terzoError?.street.error}
                      errorMessage={terzoError?.street.message}
                    />
                    <TextInput
                      label={"Numero civico"}
                      validationChecks={[]}
                      onValueChange={(value) =>
                        setTerzoData &&
                        setTerzoData({ ...terzoData!, streetNumber: value })
                      }
                      initialValue={terzoData?.streetNumber}
                      dataCy="name-terzo-input"
                      placeholder="Numero civico"
                      initialError={terzoError?.streetNumber}
                    />
                  </div>
                  <div className="flex gap-6 mt-2">
                    <PredictiveSearch
                      label={"Città"}
                      validationChecks={[]}
                      onValueChange={(value) =>
                        setTerzoData &&
                        setTerzoData({ ...terzoData!, city: value })
                      }
                      initialValue={terzoData?.city}
                      dataCy="name-terzo-input"
                      placeholder="Scrivi la città"
                      options={cityList}
                      initialError={terzoError?.city}
                    />

                    <TextInput
                      label={"CAP"}
                      validationChecks={[]}
                      onValueChange={(value) =>
                        setTerzoData &&
                        setTerzoData({ ...terzoData!, zipCode: value })
                      }
                      initialValue={terzoData?.zipCode}
                      dataCy="lastname-terzo-input"
                      placeholder="Scrivi il codice postale"
                      initialError={terzoError?.zipCode}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FormBeneficiaries;
