import React from "react";
import numberFormat from "../../../../../utils/numberFormat";
import FormBankEntity from "../FormBankEntity.component";
import FormLegalPerson from "../FormLegalPerson.component";
import FormPhysicalPerson from "../FormPhysicalPerson.component";
import { FormProps } from "../FromInterface.model";
import FormOtherBeneficiariesController from "./FormOtherBeneficiaries.controller";
import "./FormOtherBeneficiaries.styles.scss";
import { ButtonDS, GlobalIcon, ModalDS } from "get-life-storybook-ts";

const FormOtherBeneficiaries: React.FC<FormProps> = ({
  values,
  setValue,
  translate,
  disabled,
}) => {
  const {
    currentCapital,
    error,
    formType,
    handles,
    normalizeShowInformation,
    setShowModal,
    setValueBeneficiaries,
    showModal,
    type,
    valuesBeneficiaries,
    SQLCapitals,
  } = FormOtherBeneficiariesController({ values, setValue, translate });

  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

  const Forms: any = {
    legalPerson: (
      <FormLegalPerson
        translate={translate}
        values={valuesBeneficiaries}
        setValue={setValueBeneficiaries}
        error={error}
      />
    ),
    physicalPerson: (
      <div className="grid grid-cols-2 gap-[24px]">
        <FormPhysicalPerson
          translate={translate}
          values={valuesBeneficiaries}
          setValue={setValueBeneficiaries}
          handleCheck={handles.handleCheck}
          error={error}
        />
      </div>
    ),
    bankingEntity: (
      <FormBankEntity
        translate={translate}
        values={valuesBeneficiaries}
        setValue={setValueBeneficiaries}
        error={error}
      />
    ),
  };

  const buttonDisabled =
    disabled || valuesBeneficiaries.length >= 20 || !currentCapital;

  const isSQL = process.env.REACT_APP_DOMAIN_LOCALE === "sql_es";

  return (
    <div className="pl-[24px] flex flex-col gap-[24px]">
      <p className="text-[#424242] font-bold BodyM">
        {translate("postStack.title.beneficiaries")}
      </p>
      <div className="flex flex-row gap-[40px]">
        <ButtonDS
          disabled={buttonDisabled}
          buttonType="tertiary"
          onClick={() => handles.handleOpen("physicalPerson")}
          label={translate("postStack.buttons.physicalPerson")}
          leftIcon="PlusThinIcon"
          size="40"
        />
        <ButtonDS
          disabled={buttonDisabled}
          buttonType="tertiary"
          onClick={() => handles.handleOpen("legalPerson")}
          label={translate("postStack.buttons.legalPerson")}
          leftIcon="PlusThinIcon"
          size="40"
        />
        {DOMAIN !== "fr" && (
          <ButtonDS
            disabled={buttonDisabled}
            buttonType="tertiary"
            onClick={() => handles.handleOpen("bankingEntity")}
            label={translate("postStack.buttons.bankEntity")}
            leftIcon="PlusThinIcon"
            size="40"
          />
        )}
      </div>
      <div className="flex flex-col gap-[8px]">
        <p className="BodyM text-[#424242]">
          <span className="font-medium text-[var(--dark-primary-color)] mr-[16px]">
            {translate("poststack.capital.to.be.assigned")}
          </span>
          {isSQL ? (
            <span className="">
              {SQLCapitals &&
                Object.keys(SQLCapitals?.capitals).map((c, idx) => {
                  const { active, capital } = (SQLCapitals?.capitals as any)[c];

                  const getLabel = () => {
                    if (c === "DEATH")
                      return translate(
                        "dashboard.multiproduct.mandatoryDecease"
                      );
                    if (c === "SERIOUS_ILLNESS")
                      return translate("dashboard.multiproduct.seriousIllness");
                    if (c === "DISABILITY")
                      return translate(
                        "dashboard.multiproduct.absoluteDisability"
                      );
                    if (c === "TEMPORARY_DISABILITY")
                      return translate(
                        "dashboard.multiproduct.totalDisability"
                      );
                  };

                  if (!active) return undefined;
                  return (
                    <React.Fragment key={`${c}_${idx}`}>{`${numberFormat(
                      capital,
                      "capital"
                    )} (${getLabel()})`}</React.Fragment>
                  );
                })}
              {` = `}
            </span>
          ) : undefined}
          <span>
            {numberFormat(currentCapital, "capital")}
            <b> / {numberFormat(values.capital, "capital")}</b>
          </span>
        </p>
        <p className="BodyM font-medium text-[#000000]">
          {translate("postStack.text.capital")}
        </p>
      </div>

      <div
        className="grid gap-x-[32px] gap-y-[24px]"
        style={{ gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))" }}
      >
        {!!values.beneficiariesList.length &&
          normalizeShowInformation().map(
            ({ idNumber, capital, name, title, type, id }) => {
              const cardColor = (() => {
                if (type === "physicalPerson")
                  return "var(--dark-primary-color)";
                if (type === "legalPerson") return "var(--avo-green-100)";
                if (type === "bankingEntity") return "#FF6B00";
              })();

              return (
                <div
                  className="border rounded-[8px] bg-[#F5F7FA] p-[12px_24px_8px]"
                  style={{ borderColor: cardColor }}
                >
                  <div
                    className="flex flex-row gap-[8px] justify-between BodyL font-bold items-center"
                    style={{ color: cardColor }}
                  >
                    {title}
                    <button
                      className="p-[4px]"
                      onClick={() => !disabled && handles.handleDelete(id)}
                    >
                      <GlobalIcon iconName="XIcon" size="XS" />
                    </button>
                  </div>
                  <div className="flex flex-col mt-[12px] BodyM">
                    <p className="font-bold text-[#424242]">{name}</p>
                    <p className="text-[#555555] font-medium">{idNumber}</p>
                  </div>
                  <div className="flex flex-row gap-[8px] items-center mt-[4px]">
                    <p className="text-[#424242] BodyM font-medium flex-1 break-all">
                      {translate("poststack.capital.to.be.assigned")}
                      <br />
                      <b>{numberFormat(capital, "capital")}</b>
                    </p>
                    <ButtonDS
                      disabled={disabled}
                      buttonType="tertiary"
                      onClick={() => handles.handleEdit(id)}
                      label={translate("postStack.buttons.change")}
                      size="32"
                      className="h-fit"
                    />
                  </div>
                </div>
              );
            }
          )}
      </div>
      <ModalDS
        title={translate(`postStack.title.${type}`)}
        content={Forms[type]}
        buttons={
          <>
            <ButtonDS
              buttonType="secondary"
              onClick={() => handles.handleClose()}
              label={translate("postStack.buttons.cancel")}
            />
            <ButtonDS
              onClick={() => handles.handleSubmit()}
              label={translate("poststack.modal.continue")}
            />
          </>
        }
        open={showModal}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default FormOtherBeneficiaries;
