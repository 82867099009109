import React from "react";
import { errorMessage } from "../../../../utils/error";
import { FormProps } from "./FromInterface.model";
import { InputDS } from "get-life-storybook-ts";

const FormLegalPerson: React.FC<FormProps> = ({
  error,
  setValue,
  translate,
  values,
}): JSX.Element => {
  return (
    <div className="flex flex-col gap-[24px]">
      <InputDS
        value={values["businessName"]}
        label={translate("postStack.legalPersonForm.name")}
        onChange={(e) => setValue("businessName", e.target.value)}
        placeholder={translate("beneficiaries.placeholder.businessName")}
        error={errorMessage(error, "businessName")}
      />
      <InputDS
        value={values["fiscalNumber"]}
        label={translate("postStack.legalPersonForm.idNumber")}
        onChange={(e) => setValue("fiscalNumber", e.target.value)}
        placeholder={translate("beneficiaries.placeholder.fiscalNumber")}
        error={errorMessage(error, "fiscalNumber")}
      />
      <InputDS
        value={values["capital"]}
        label={translate("global.capital")}
        type="number"
        onChange={(e) => setValue("capital", e.target.value)}
        placeholder={translate("beneficiaries.placeholder.name")}
        error={errorMessage(error, "capital")}
      />
    </div>
  );
};

export default FormLegalPerson;
